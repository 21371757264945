<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <KontainerFilter v-on:onFilterChange="onFilterChange"></KontainerFilter>
    <KontainerDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></KontainerDialog>
    <KontainerBindDialog
      :show="showDialog2"
      :action="action2"
      :item="dialogItem2"
      :containerBox="containerBox"
      max_width="80%"
      v-on:onClose="onCloseEvent2"
      v-on:onSave="onBindKontainer"
    ></KontainerBindDialog>
    <KontainerDetailDialog
      :show="showDialog3"
      :action="action3"
      :item="dialogItem3"
      :containerDetail="containerDetail"
      :totalItems="dialogTotalItems"
      max_width="80%"
      v-on:onClose="onCloseEvent3"
      v-on:removeContainerBox="removeContainerBox"
      v-on:getContainerBoxDetail="getContainerBoxDetail"
    ></KontainerDetailDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.list')"
      class="px-5 py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.total_cubic_feet="{ item }">
          {{ fixedFloat(item.total_cubic_feet, 2, 0) }}
        </template>
        <template v-slot:item.export="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                @click="exportCommodityList(item)"
                :disabled="item.status==9"
              >
                {{ $t("commodity.list") }}
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportCustomerReceiptList(item)"
                :disabled="item.status==9"
              >
                {{ $t("customer_receipt_list") }}
              </v-btn>
            </template>
            <span></span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportReceiptList(item)"
                :disabled="item.status==9"
              >
                {{ $t("receipt_list") }}
              </v-btn>
            </template>
            <span></span>
          </v-tooltip> -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportCustomerList(item)"
                :disabled="item.status==9"
              >
                {{ $t("customer.list") }}
              </v-btn>
            </template>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportApplyList(item)"
                :disabled="item.status==9"
              >
                {{ $t("apply_list") }}
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportReceiveLite(item)"
                :disabled="item.status==9"
              >
                {{ $t("receipt_sheet_lite") }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
                :disabled="item.status==9"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewDetail(item)"
                :disabled="item.status==9"
              >mdi-eye</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="addContainerBox(item)"
                :disabled="item.status==9"
              >mdi-plus-box</v-icon>
            </template>
            <span>{{ $t("container.add") }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="closeItem(item)"
              >mdi-check</v-icon>
            </template>
            <span>{{ $t("container.finish") }}</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="disableItem(item)"
                :disabled="item.status==9"
              >mdi-block-helper</v-icon>
            </template>
            <span>{{ $t("container.disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import KontainerFilter from "../filters/KontainerFilter"
import KontainerDialog from "../dialogs/KontainerDialog";
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import KontainerBindDialog from "../dialogs/KontainerBindDialog";
import KontainerDetailDialog from "../dialogs/KontainerDetailDialog"
import BaseTable from "./BaseTable.vue";
import { action_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/container/container/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.order_number"),
        value: "order_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_cubic_feet"),
        value: "total_cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "cutoff_date",
      },
      {
        sortable: false,
        text: vm.$i18n.t("export"),
        value: "export",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    items: [],
    options: {},
    totalItems: 0,
    loading: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100],
    },
    filterItem: {
      status: 1
    },
    selected: [],
    action_items: action_items,
    // containerBindDialog
    showDialog2: false,
    action2: action_items.view,
    dialogItem2: {},
    defaultItem2: {},
    containerBox: [],
    // detailDialog
    showDialog3: false,
    action3: action_items.view,
    dialogItem3: {},
    defaultItem3: {},
    containerDetail: [],
    selectedContainerId: null,
    dialogTotalItems: 0,
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    KontainerFilter,
    KontainerDialog,
    CommonAlertDialog,
    KontainerBindDialog,
    KontainerDetailDialog,
  },
  methods: {
    closeItem(item){
      if (confirm(this.$i18n.t("container.close-confirm"))) {
        this.postApi(item, "/container/container/"+ item.id +"/closeContainer/");
      }
    },
    disableItem(item){
      if (confirm(this.$i18n.t("container.disable-confirm",{container:item.container_number}))) {
        this.postApi(item, "/container/container/"+ item.id +"/disableContainer/");
      }
    },
    addContainerBox(item){
      // get boxes
      this.getApi("/container/container_box/", this.setContainerBoxItems, false, {}, {"container__isnull": true,"status": this.container_status.enable})
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      this.dialogItem2.container = item.id
      this.dialogItem2.total_box_amount = item.total_box_amount
      this.dialogItem2.total_cubic_feet = item.total_cubic_feet
      this.dialogItem2.total_gross_weight = item.total_gross_weight
      this.action2 = this.action_items.create;
      this.showDialog2 = true;
    },
    setContainerBoxItems(data){
      this.containerBox = data.results
    },
    onCloseEvent2(item) {
      this.showDialog2 = false;
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
    },
    onBindKontainer(item) {
      this.showDialog2 = false;
      if (item.boxes) {
        this.postApi(item, "/container/container/"+ item.container +"/bindContainer/");
      }
    },
    exportCommodityList(item) {
      const url = "/container/container/" + item.id + "/getCommodityList/";
      const title = this.$t("container.number");
      this.getFileApi(url, "貨品清單");
    },
    exportCustomerReceiptList(item) {
      const url = "/container/container/" + item.id + "/getCustomerReceiptList/";
      this.getFileApi(url, "客戶簽收單");
    },
    exportApplyList(container) {
      console.log('container',container)
      let objectDate = new Date();
      let file_name =
        objectDate.getFullYear() +
        "年" +
        (objectDate.getMonth() + 1) +
        "月" +
        objectDate.getDate() +
        "日_" +
        container.container_number +
        "_報關用";
      const url = "/container/container/" + container.id + "/getApplyListCn/";
      this.getFileApi(url, file_name);
    },
    exportCommodityImages(item) {
      // TODO
      const url = "/container/container/getCommodityImages/";
      this.getFileApi(url, "商品照.docx");
    },
    viewDetail(item) {
      this.getApi("/container/container_box/", this.setContainerBoxDetail,false,{},{container: item.id});
      this.selectedContainerId = item.id
      // this.dialogItem = this._.cloneDeep(item);
      // this.action = action_items.view;
      this.showDialog3 = true;
    },
    getContainerBoxDetail(options){
      this.getApi("/container/container_box/", this.setContainerBoxDetail,false,{},{container: this.selectedContainerId},options);
    },
    setContainerBoxDetail(data) {
      this.dialogTotalItems = data.count
      this.containerDetail = data.results
    },
    onCloseEvent3(item) {
      this.showDialog3 = false;
    },
    removeContainerBox(item) {
      this.postApi([], "/container/container_box/"+ item.id +"/unbindContainer/", this.afterRemoveContainerBox);
    },
    afterRemoveContainerBox(action, data) {
      console.log('afterRemoveContainerBox',action,data)
      this.setContainerBoxDetail({results: data})
    },
    exportReceiveLite(item){
      console.log('item',item)
      const url = "/container/container/" + item.id + "/getReceiptListLite/";
      this.getFileApi(url, "簡易領貨單");
    },
  },
  mounted() {},
};
</script>
