<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card>
        <v-data-table
          :headers="summary_headers"
          :items="summary_items"
          :hide-default-footer="true"
        >
          <template v-slot:item.cubic_feet="{ item }">
            {{ fixedFloat(item.cubic_feet, 2, 0) }}
          </template>
        </v-data-table>
      </v-card>
      <v-card>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="containerBox"
          show-select
        >
          <template v-slot:item.commodity_name="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity.name ? data.commodity.name : '&nbsp;' }}</div>
            </template>
          </template>
          <template v-slot:item.commodity_number="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity.commodity_number }}</div>
            </template>
          </template>
          <template v-slot:item.commodity_total_amount="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity_amount }}</div>
            </template>
          </template>
          <template v-slot:item.supplier="{ item }">
            {{ get_supplier_name(item) }}
          </template>
          <template v-slot:item.customer="{ item }">
            {{ get_customer_name(item) }}
          </template>
        </v-data-table>
      </v-card>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    containerBox: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.box"),
    summary_headers: [
      {
        sortable: false,
        text: "",
        value: "type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.gross_weight"),
        value: "gross_weight",
      },
    ],
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "code",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.start_box"),
        value: "start_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.end_box"),
        value: "end_box",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity_name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_total_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer",
      },
      //   {
      //     sortable: true,
      //     text: vm.$i18n.t("container.number"),
      //     value: "container.container_number",
      //   },
      //   {
      //     sortable: true,
      //     text: vm.$i18n.t("container.stuffing-date"),
      //     value: "container.stuffing_date",
      //   },
      //   {
      //     sortable: true,
      //     text: vm.$i18n.t("container.cutoff-date"),
      //     value: "container.cutoff_date",
      //   },
    ],
    selected: [],
    new_summary: {
      type: vm.$i18n.t("container.summary"),
      box_amount: 0,
      cubic_feet: 0,
      gross_weight: 0,
    },
    selected_summary: {
      type: vm.$i18n.t("container.selected"),
      box_amount: 0,
      cubic_feet: 0,
      gross_weight: 0,
    },
  }),
  computed: {
    summary_items() {
      return [this.selected_summary,this.new_summary];
    },
  },
  mixins: [BaseDialog],
  methods: {
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    save() {
      var boxes = [];
      let editedItem = this._.cloneDeep(this.item);
      if (this.selected.length > 0) {
        for (const [key, box] of Object.entries(this.selected)) {
          boxes.push(box.id);
        }
        editedItem.boxes = boxes;
        editedItem.total_box_amount = this.new_summary.box_amount;
        editedItem.total_cubic_feet = this.new_summary.cubic_feet;
        editedItem.total_gross_weight = this.new_summary.gross_weight;
        this.$emit("onSave", editedItem);
      }
    },
    close() {
      this.selected = []
      this.$emit("onClose");
    },
  },
  watch: {
    selected(obj) {
    //   console.log("selected", obj);
      this.selected_summary.box_amount = this.arraySum(obj, "box_amount");
      this.selected_summary.cubic_feet = this.arraySum(obj, "cubic_feet");
      this.selected_summary.gross_weight = this.arraySum(obj, "gross_weight");

      this.new_summary.box_amount = this.item.total_box_amount + this.selected_summary.box_amount
      this.new_summary.cubic_feet = this.item.total_cubic_feet + this.selected_summary.cubic_feet
      this.new_summary.gross_weight = this.item.total_gross_weight + this.selected_summary.gross_weight
    },
    item(val) {
    //   console.log("item", val);
      this.new_summary.box_amount = val.total_box_amount
      this.new_summary.cubic_feet = val.total_cubic_feet
      this.new_summary.gross_weight = val.total_gross_weight
    },
  },
};
</script>