<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card height="65vh">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.container_number"
                :label="$t('container.number')"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>{{ $t('container.number') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="action != action_items.create">
              <v-text-field
                v-model="item.order_number"
                :label="$t('container.order_number')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <DatePicker
                v-model="item.stuffing_date"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :value="inputValue"
                    :label="$t('container.stuffing-date')"
                    v-on="inputEvents"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('container.stuffing-date') }}
                    </template>
                  </v-text-field>
                </template>
              </DatePicker>
            </v-col>
            <v-col>
              <DatePicker
                v-model="item.cutoff_date"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :value="inputValue"
                    :label="$t('container.cutoff-date')"
                    v-on="inputEvents"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('container.cutoff-date') }}
                    </template>
                  </v-text-field>
                </template>
              </DatePicker>
            </v-col>
          </v-row>
        </v-container>
        <v-card v-if="action === action_items.create">
          <v-data-table
            :headers="summary_headers"
            :items="selected_summary"
            :hide-default-footer="true"
          >
            <template v-slot:item.cubic_feet="{ item }">
              {{ fixedFloat(item.cubic_feet, 2, 0) }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
export default {
  props: {
    summary_headers: [],
    selected_summary: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container.container"),
    date: new Date(),
    modelConfig: {
      type: "string",
      mask: "YYYY-MM-DD", // Uses 'iso' if missing
    },
  }),
  computed: {
    allowSave() {
      return !(this.item.container_number &&
      this.item.stuffing_date &&
      this.item.cutoff_date)
    },
  },
  components: {
    DatePicker,
  },
  mixins: [BaseDialog],
};
</script>
