<template>
  <v-container
    id="kontainer.filter"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        {{ $t('container.stuffing-date') }}
        <CommonDateFilter
        v-on:setTimeRange="setTimeRange"
      ></CommonDateFilter>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="filterItem.status"
          :label="$t('container.status')"
          :items="container_status_items"
          :item-text="item => $t(item.text)"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="onChange"
        >
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
import { container_status_items } from "@/definition.js";
import CommonDateFilter from "./CommonDateFilter";
export default {
  data: (vm) => ({
    container_status_items: container_status_items,
    filterItem: {
      stuffing_date__range: {
        start: new Date(),
        end: new Date(),
      },
      status: 1,
    },
    timeRange: null,
    modelConfig: {
      type: "string",
      mask: "YYYY-MM-DD", // Uses 'iso' if missing
    },
  }),
  components: {
    CommonDateFilter,
  },
  mixins: [BaseFilter],
  methods: {
    onChange() {
      this.filterItem.stuffing_date__range = this.timeRange;
      this.$emit("onFilterChange", this.filterItem);
    },
    setTimeRange(val) {
      console.log("setTimeRange");
      console.log(val);
      this.timeRange = val;
    },
  },
};
</script>