var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.max_width},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.summary_headers,"items":_vm.summary_items,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.cubic_feet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(item.cubic_feet, 2, 0))+" ")]}}])})],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.containerBox,"show-select":""},scopedSlots:_vm._u([{key:"item.commodity_name",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.name ? data.commodity.name : ' '))])]})]}},{key:"item.commodity_number",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.commodity_number))])]})]}},{key:"item.commodity_total_amount",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity_amount))])]})]}},{key:"item.supplier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_supplier_name(item))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),(_vm.action != _vm.action_items.view)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }