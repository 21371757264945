var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.max_width},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"height":"65vh"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('container.number')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('container.number'))+" ")]},proxy:true}]),model:{value:(_vm.item.container_number),callback:function ($$v) {_vm.$set(_vm.item, "container_number", $$v)},expression:"item.container_number"}})],1),(_vm.action != _vm.action_items.create)?_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('container.order_number')},model:{value:(_vm.item.order_number),callback:function ($$v) {_vm.$set(_vm.item, "order_number", $$v)},expression:"item.order_number"}})],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('DatePicker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"value":inputValue,"label":_vm.$t('container.stuffing-date')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('container.stuffing-date'))+" ")]},proxy:true}],null,true)},inputEvents))]}}]),model:{value:(_vm.item.stuffing_date),callback:function ($$v) {_vm.$set(_vm.item, "stuffing_date", $$v)},expression:"item.stuffing_date"}})],1),_c('v-col',[_c('DatePicker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"value":inputValue,"label":_vm.$t('container.cutoff-date')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('container.cutoff-date'))+" ")]},proxy:true}],null,true)},inputEvents))]}}]),model:{value:(_vm.item.cutoff_date),callback:function ($$v) {_vm.$set(_vm.item, "cutoff_date", $$v)},expression:"item.cutoff_date"}})],1)],1)],1),(_vm.action === _vm.action_items.create)?_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.summary_headers,"items":_vm.selected_summary,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.cubic_feet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(item.cubic_feet, 2, 0))+" ")]}}],null,false,331264458)})],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),(_vm.action != _vm.action_items.view)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.allowSave},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }