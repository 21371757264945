var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('KontainerFilter',{on:{"onFilterChange":_vm.onFilterChange}}),_c('KontainerDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent}}),_c('KontainerBindDialog',{attrs:{"show":_vm.showDialog2,"action":_vm.action2,"item":_vm.dialogItem2,"containerBox":_vm.containerBox,"max_width":"80%"},on:{"onClose":_vm.onCloseEvent2,"onSave":_vm.onBindKontainer}}),_c('KontainerDetailDialog',{attrs:{"show":_vm.showDialog3,"action":_vm.action3,"item":_vm.dialogItem3,"containerDetail":_vm.containerDetail,"totalItems":_vm.dialogTotalItems,"max_width":"80%"},on:{"onClose":_vm.onCloseEvent3,"removeContainerBox":_vm.removeContainerBox,"getContainerBoxDetail":_vm.getContainerBoxDetail}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.total_cubic_feet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(item.total_cubic_feet, 2, 0))+" ")]}},{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"small":"","disabled":item.status==9},on:{"click":function($event){return _vm.exportCommodityList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("commodity.list"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","disabled":item.status==9},on:{"click":function($event){return _vm.exportCustomerReceiptList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("customer_receipt_list"))+" ")])]}}],null,true)},[_c('span')]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","disabled":item.status==9},on:{"click":function($event){return _vm.exportApplyList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("apply_list"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","disabled":item.status==9},on:{"click":function($event){return _vm.exportReceiveLite(item)}}},[_vm._v(" "+_vm._s(_vm.$t("receipt_sheet_lite"))+" ")])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.status==9},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.status==9},on:{"click":function($event){return _vm.viewDetail(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.status==9},on:{"click":function($event){return _vm.addContainerBox(item)}}},on),[_vm._v("mdi-plus-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.add")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.status==9},on:{"click":function($event){return _vm.disableItem(item)}}},on),[_vm._v("mdi-block-helper")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.disable")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }